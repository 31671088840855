import * as React from "react";
import { Helmet } from "react-helmet";
import scrollTo from "gatsby-plugin-smoothscroll";
import { withPrefix , Link } from 'gatsby';
import ArrowBlank from "../svg/arrow_blank.svg";
import Scene from "../components/scene";
import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import Scroller from "../svg/scroller.svg";

import "../scss/main.scss";
import { IMG_BASE, FRONT_DOMAIN } from "../config";
import FAQ from "../components/FAQ";

const CommunityPage = ({pageContext: {community, faq, contacts}}) => {
    const [screen1, screen2] = community.items;
    const socialList = contacts.items;
    const preparedSocialList = socialList.reduce((acc, item) => ({
        ...acc,
        [item.name.toLowerCase()]: item
    }), {});
    const pdfUrl = 'https://www.canva.com/design/DAGRo8DMsWg/V56NUplyX3BCSukt3w-11w/view?utm_content=DAGRo8DMsWg&utm_campaign=designshare&utm_medium=link&utm_source=editor#19';

    const {email, whatsapp, phone, telegram, instagram, facebook, linkedin} = preparedSocialList;
    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);


    const PortfolioButton = () => {

        return (
            <section id="join-teleport" className="site-section">
                <div className="content-section">
                    <div className="corners">
                        <Link to={pdfUrl} target="_blank" download className="get-tickets">
                            DOWNLOAD OUR PORTFOLIO

                            <ArrowBlank className="get-tickets__arrow" />
                        </Link>

                        <TopLeftCorner className="corners__corner corners__corner_top-left" />
                        <TopRightCorner className="corners__corner corners__corner_top-right" />
                        <BottomRightCorner className="corners__corner corners__corner_bottom-right" />
                        <BottomLeftCorner className="corners__corner corners__corner_bottom-left" />
                    </div>
                </div>
                <div className="contacts-list-wrapper">
                    <p className="contacts-list-wrapper__title">Connect with Teleport:</p>

                    <ul className="contacts-list">
                        {instagram &&
                        <li className="contacts-list__item">
                            <a href={instagram.url} className="contacts-list__link" target="_blank" rel="noreferrer">
                                <img src={`${IMG_BASE}/${instagram.icon}`} alt="" className="contacts-list__icon"/>
                            </a>
                        </li>
                        }
                        {facebook &&
                        <li className="contacts-list__item">
                            <a href={facebook.url} className="contacts-list__link" target="_blank" rel="noreferrer">
                                <img src={`${IMG_BASE}/${facebook.icon}`} alt="" className="contacts-list__icon"/>
                            </a>
                        </li>
                        }

                    </ul>
                </div>
            </section>
        )
    }

    return (
        <>
            <Helmet>
                <title>Services | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <Scene />

            <section className="site-section">
                <div className="content-section" style={ { "--width": "98rem" } }>
                    <h1
                        className="content-section__title content-section__title_second"
                        dangerouslySetInnerHTML={{__html: screen1.name}}
                    >
                        {/* Teleport is&nbsp;the latest brainchild&nbsp;of our forward-thinking collective, united by&nbsp;a&nbsp;boundless enthusiasm for creativity, technology, art and design</h1> */}
                    </h1>
                    <div
                        className="content-section__text" style={ { "--width": "75%" } }
                        dangerouslySetInnerHTML={{__html: screen1.description}}
                    >
                        {/* <p>At&nbsp;our core, we&nbsp;share a&nbsp;desire to&nbsp;bring people of&nbsp;diverse backgrounds, experiences, cultures, and identities together.</p> */}
                    </div>
                </div>

                <button className="scroller site-section__scroller" type="button" onClick={ () => scrollTo("#faq-teleport") }>
                    <Scroller className="scroller__arrow" />
                </button>
            </section>

            <section className="site-section" id="faq-teleport">
                 <FAQ faqList={faq.items}/>
            </section>

            <PortfolioButton/>
        </>
    )
}

export default CommunityPage;
